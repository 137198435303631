
import './App.css';
import bg from './imgs/snowing.gif'

import discord from './imgs/discord.png'
import tg from './imgs/icon--telegram.png'
import x from './imgs/icon--twitter.png'
function App() {
  return (
    <div className="App f">
      <div className="bg" style={{backgroundImage: `url(${bg})`}}>
        {
          // <div className="logo"><img src={hw}/></div>
        }
        <div className="links">
          <a href="https://discord.gg/3BUen4F9Tw" target="_blank"><img src={discord} /></a>
          
          <a href="https://t.me/derpedewdz" target="_blank"><img src={tg}/></a>
          
          <a href="https://x.com/derpedewdz" target="_blank"><img src={x}/></a>
          <a href="https://docs.derpe.xyz" target="_blank">Docs</a>
        </div> 
      </div>
    </div>
  );
}

export default App;
